

/*
* Colors
*/
$color-primary: #c27b7f;
$color-secondary: #876585;
$color-tertiary: #fcb0b4;


$color-light: #ffffff;
$color-dark: #2f4858;
$color-gray: #676768;
$color-border: #eff0f7;
$color-success: #00b374;
$color-warning: #ffbb1c;
$color-danger: #ca3604;
$color-shadow: rgba(1, 6, 33, 0.06);
$bg-one: #f5ebe6;


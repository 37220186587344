.blog__single-post__image {
  max-height: 600px;
  text-align: center;
}

.blog__single-post__meta {
  padding: 0 30px 30px;
  background-color: #ffffff;
}

.book-item-release-date {
  text-transform: capitalize;
}
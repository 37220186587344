@import "./abstracts/mixins";
@import "./abstracts/animations";
@import "./abstracts/variables";

@import "./base/typography";
@import "./base/base";

@import "./components/header";
@import "./components/hero";
@import "./components/achive";
@import "./components/chapter";
@import "./components/author";
@import "./components/testimonial";
@import "./components/contact-form";
@import "./components/best-sells";
@import "./components/books";
@import "./components/pricing";
@import "./components/counter";
@import "./components/blog";
@import "./components/cta";
@import "./components/chapterPreview";
@import "./components/achievement";
@import "./components/events";
@import "./components/footer";

@import "./pages/all-blogs";
@import "./pages/blog-right-sidebar";
@import "./pages/single-blog";
@import "./pages/single-event";

.gnext.gbtn {
  display: none;
}

.cursor {
  cursor: pointer;
}

.booksv1__img > img {
  //max-height: 380px;
}

.blog__single-post:hover {
  background: #f5ebe6;
}

.blog__single-post .blog__single-post__meta,
.blog__single-post .blog__single-post__body {
  background: transparent;
}